import React from 'react'
import {HStack,Avatar,Text} from "@chakra-ui/react"
import '../App.css'

const Message = ({text,uri, user="other"}) => {
  return (
    // ek horizontal flex box banane k liye
    <HStack className='button-17' role='button' alignSelf={user==="me"?"flex-end":"flex-start"}  paddingY={"2"} paddingX={user=== "me"?"2":"2"} bg={user=== "me"?"#9bc2c0":"white"} color={user=== "me"?"white":"black"}>

        {
            user === "other" && <Avatar src={uri} /> //profile emoji ko left mein karne k liye
        }

        <Text>{text}</Text>
        
        {
            user==="me" && <Avatar src={uri} /> //profile emoji ko right mein karne k liye
        }
    </HStack>
  )
}

export default Message