import { useEffect, useRef, useState } from "react";
import {Box,Button,Container,HStack,Input,VStack} from "@chakra-ui/react"
import Message from "./Components/Message";
import {onAuthStateChanged ,getAuth,GoogleAuthProvider,signInWithPopup,signOut} from "firebase/auth"
import { app } from "./firebase";
import {getFirestore, addDoc, collection, serverTimestamp,onSnapshot,query,orderBy} from "firebase/firestore"
import './App.css'

const auth = getAuth(app);

//data stiarage
const db = getFirestore(app);

//for login
const loginHandler = ()=>{

  const provider = new GoogleAuthProvider();

  signInWithPopup(auth,provider)
}

//for logout
const logoutHandler = () => {

  signOut(auth)
}

//



//
function App() {


  //for user to login or logout
  const [user,setUser] = useState(false);
  const [message,setMessage] = useState("");

  const [messages,setMessages] = useState([]);

  const divForScroll = useRef(null);

  const submitHandler =async(e)=>{

        e.preventDefault();

        try {
          
          setMessage("");
          await addDoc(collection(db,"Messages"),{
            text:message,
            uid: user.uid,
            uri:user.photoURL,
            createdAt:serverTimestamp()
          });

          divForScroll.current.scrollIntoView({ behavior: "smooth" });
          
        } catch (error) {
          
          alert(error)
        }
    }


  useEffect(()=>{
    const q = query(collection(db,"Messages"),orderBy("createdAt","asc"))

    const unsubscribe = onAuthStateChanged(auth,(data)=>{
      setUser(data)
    });

    //for taking data
    const unsubscribeForMessage = onSnapshot(q,(snap)=>{
      setMessages(snap.docs.map((item)=>{
        const id = item.id;
        return {id, ...item.data()}
      }))
    })

    return ()=>{
      unsubscribe();
      unsubscribeForMessage();
    }
  }, []);


  return (
    <Box className="box">

      {
        user?(
          <Container className='container' h={"100vh"} bg={"white"}>

      <VStack h={"full"} paddingY={"3"}>
        <Button color={"black"} bg={"white"} w={"full"} onClick={logoutHandler}>Logout</Button>

        <VStack height={"full"} width={"full"} overflowY={"auto"} css={{"&::-webkit-scrollbar":{display: "none"}}}>

          {

            messages.map(item=>(
              <Message key={item.id} user={item.uid===user.uid?"me":"other"} text={item.text} uri={item.uri} />
              ))
          }

          <div ref={divForScroll}></div>
        </VStack>



        <form onSubmit={submitHandler} style={{ width: "100%"}}>

          <HStack>
            <Input bg={"white"} value={message} onChange={(e)=>setMessage(e.target.value)} placeholder="Enter a message....."/>
            <Button color={"black"} bg={"white"} type="submit">send</Button>
          </HStack>

        </form>
      </VStack>

      </Container>
        ):<VStack bg={"white"} justifyContent={"center"} h="100vh">
            <p className="welcome">Welcome to ChatApp</p>

            <Button color={"white"} onClick={loginHandler} bg={"#009b7d"} >sign in with google</Button>
          
        </VStack>
      }

    </Box>
  );
}

export default App;
