// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBu7sQSbQgSBz-TAm8Pb_H_Hd_2TFyXZdM",
  authDomain: "abhit-chatapp.firebaseapp.com",
  projectId: "abhit-chatapp",
  storageBucket: "abhit-chatapp.appspot.com",
  messagingSenderId: "288874398529",
  appId: "1:288874398529:web:150b8b6818994c348797c0"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);